.services {
    padding-top: 8px;
    margin-bottom: 148px;
    background: url("../../../assets/img/dots.jpg");
    &__title,
    &__name {
        font-weight: 800;
    }
    &__title {
        margin-bottom: 52px;
        font-size: 60px;
        text-align: center;
        color: rgb(var(--blackRock));
    }
    &__item {
        width: 25%;
        &:hover {
            .services__name {
                text-decoration: underline;
            }
        }
    }
    &__img {
        margin-bottom: 35px;
    }
    &__name {
        font-size: 30px;
        line-height: 36px;
        color: rgb(var(--dodgerBlue));
    }
}

@media (max-width: 1300px) {
    .services {
        &__name {
            font-size: 24px;
        }
    }
}

@media (max-width: 1080px) {
    .services {
        margin-bottom: 100px;
        &__item {
            margin-bottom: 30px;
        }
        &__img {
            margin-bottom: 25px;
        }
        &__name {
            font-size: 18px;
        }
    }
}

@media (max-width: 992px) {
    .services {
        &__item {
            width: 50%;
        }
        &__img {
            img {
                margin: 0 auto;
            }
        }
        &__name {
            text-align: center;
        }
    }
}

@media (max-width: 767px) {
    .services {
        margin-bottom: 60px;
    }
}

@media (max-width: 575px) {
    .services {
        &__list {
            flex-wrap: nowrap;
            overflow-x: auto;
        }
        &__item {
            flex-shrink: 0;
            width: 300px;
        }
    }
}